<template>
  <div
    class="payment-bnpl-graph"
    :style="{ '--bnpl_color': options.color }"
  >
    <div
      class="wrap"
      :class="itemNum"
    >
      <div
        v-for="(item, idx) of list"
        :key="`item-${idx}`"
        class="item"
        :class="{ 'item-last': item.isLast, 'item-start': item.isStart }"
      >
        <div class="item__percent">
          <GraphRing
            :percent="item.percent"
            :color="options.color"
          />
        </div>
        <div class="item__info-wrap">
          <div class="item__amount">
            {{ item.amount }}
          </div>
          <div class="item__txt">
            {{ item.txt }}
          </div>
        </div>
      </div>
    </div>
    <!-- <TriangleIcon class="triangle-icon" /> -->
  </div>
</template>

<script>
import { template as _commonTemplate } from '@shein/common-function'
import GraphRing from './item_info_bnpl_graph_ring.vue'
export default {
  name: 'ItemInfoBnplGraph',
  components: {
    GraphRing
    // TriangleIcon
  },
  props: {
    scene: {
      type: String,
      default: 'checkout'
    },
    options: {
      type: Object,
      default() {
        return {}
      }
    },
    language: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  computed: {
    list() {
      return this.generateList()
    },
    itemNum() {
      let len = this.options.repayment_period_list.length
      if (len > 6) {
        return 'item-num-6'
      }
      return `item-num-${len}`
    }
  },
  methods: {
    template: _commonTemplate,
    pickLanguageTxt(num, unit) {
      switch (unit) {
        case 'day':
          return this.template(
            num,
            num === 1
              ? this.language.SHEIN_KEY_PWA_30879
              : this.language.SHEIN_KEY_PWA_30878
          )
        case 'week':
          return this.template(
            num,
            num === 1
              ? this.language.SHEIN_KEY_PWA_30881
              : this.language.SHEIN_KEY_PWA_30880
          )
        case 'month':
          return this.template(
            num,
            num === 1
              ? this.language.SHEIN_KEY_PWA_30882
              : this.language.SHEIN_KEY_PWA_30883
          )
      }
    },
    generateList() {
      let acc = 0
      let len = this.options.repayment_period_list.length
      return this.options.repayment_period_list.map((item, idx) => {
        // let txt = 'Today'

        let txt = this.language.SHEIN_KEY_PWA_30877
        let isStart = true
        if (idx) {
          isStart = false
          // txt = `In ${
          //   (item.current_period - 1) * this.options.repayment_period
          // } ${this.options.repayment_period_unit}`
          txt = this.pickLanguageTxt(
            (item.current_period - 1) * this.options.repayment_period,
            this.options.repayment_period_unit
          )
        }
        acc += item.repayment_amount.amount
        return {
          key: `item-${idx}`,
          percent: (acc / this.options.amount) * 100,
          amount: item.repayment_amount.amountWithSymbol,
          txt,
          isStart,
          isLast: idx === len - 1
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.payment-bnpl-graph {
  position: relative;
  // height: 77/37.5rem;
  border: 1/37.5rem solid #d9d9d9;
  // border: 1/37.5rem solid ;
  border-radius: 4/37.5rem;
  margin-top: 10/37.5rem;

  &::after {
    content: ' ';
    position: absolute;
    top: -5.5/37.5rem;
    left: 35/37.5rem;
    width: 10/37.5rem;
    height: 10/37.5rem;
    border: 1/37.5rem solid #d9d9d9;
    border-top-left-radius: 1/37.5rem;
    border-bottom: none;
    border-right: none;
    background-color: #fff;
    // rotate: 45deg;
    transform: rotate(45deg);
  }
  .wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 7/37.5rem 8/37.5rem;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  .item-num-2,
  .item-num-3 {
    padding: 7/37.5rem 20/37.5rem;
  }

  // .item-num-4,
  // .item-num-5,
  // .item-num-6 {
  //   padding: 7/37.5rem 8/37.5rem;
  // }

  .item-num-2 .item {
    display: flex;
    .item__percent {
      margin-bottom: 2/37.5rem;
    }
    .item__info-wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding-left: 6/37.5rem;
      margin-bottom: 2/37.5rem;
    }
   
  }
  .item {
    min-width: 55/37.5rem;
    flex: 0 0 auto;
    line-height: 1;
    padding: 0 4/37.5rem;
    .item__percent {
      display: flex;
      justify-content: center;
      margin-bottom: 6/37.5rem;
    }
    .item__amount {
      text-align: center;
      color: var(---sui_color_gray_dark2, #666);
      font-size: 11/37.5rem;
      font-weight: 400;
      font-style: normal;
      line-height: normal;
    }
    .item__txt {
      text-align: center;
      color: var(---sui_color_gray_light1, #959595);
      font-size: 10/37.5rem;
      font-weight: 400;
      line-height: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: 'SF Pro';
    }
  }
  .item-start {
    // margin-left: 20/37.5rem;
    padding-left: 0;
    .item__amount {
      font-weight: 590;
      color: var(---sui_color_brand, #000);
    }
    .item__txt {
      font-weight: 590;
      color: var(--bnpl_color);
    }
  }
  .item-last {
    padding-right: 0;
  }

  // .triangle-icon{
  //   position: absolute;
  //   top: -5/37.5rem;
  //   left: 35/37.5rem;
  //   // width: 12/37.5rem;
  //   // height: 12/37.5rem;
  //   // border: 1/37.5rem solid #D9D9D9;
  //   // border-bottom: none;
  //   // border-right: none;
  //   color:#D9D9D9;
  //   // fill:#D9D9D9;
  //   background-color: #fff;
  // }
}
</style>
